import React from 'react';
import kebabCase from 'lodash.kebabcase';
import { Link, graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import SEO from '../components/SEO';

interface Props {
  data: {
    allMarkdownRemark: {
      group: {
        fieldValue: string;
        totalCount: number;
      }[];
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

export default ({
  data: {
    allMarkdownRemark: { group },
  },
}: Props) => (
  <>
    <SEO title="Categories" />
    <PageLayout>
      <h1>Categories</h1>
      <ul>
        {group.map((tag) => (
          <li key={tag.fieldValue} style={{ marginBottom: '1rem' }}>
            <Link to={`/categories/${kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </PageLayout>
  </>
);

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`;
